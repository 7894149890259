import { Protected } from "../data/auth";
import "./styles.css";
import Board from "react-trello";
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import Typography from '@mui/material/Typography';
import moment from "moment";
import { dateFromMoment, dateToMoment, nowdate } from "../../utils";
import { registerPanel, Sidebar, useSidebar } from '../components/Sidebar'
import DeliveryPanel from "./DeliveryPanel";
import { Dialog, registerDialog, useDialog } from '../components/Dialog';
import { fetchJSON, postCommand, useJSON, useRefresh } from "../data/fetch";
import { lightBlue, lightGreen, red, teal } from '@mui/material/colors';
import { Logout, Menu, Add, Refresh, Warning, ListAlt, Inventory, LocalShipping, Check } from "@mui/icons-material";
import COMMANDS from '../../common/commands';
import { ConfirmProvider } from 'material-ui-confirm';
import { useParams } from "react-router-dom";
import { Badge, IconButton, Tab, Tabs } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { SearchInput } from "../components/Search";
import FailedDialog from "./FailedDlg";
import { bgcolor } from "../driver/DeliveryPanel";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { backwards } from "../data/utils";
const drawerWidth = 240;

function register() {
  registerPanel({ id: 'driver_delivery', Component: DeliveryPanel })

  registerDialog({ id: 'failed', Component: FailedDialog, size: 'md' });
}

const deliverySize = d =>{
  let st = [];
  if(d.weight) st.push(`${d.weight} kg`);
  if(d.cartons) st.push(`${d.cartons} krt`);
  if(d.palettes) st.push(`${d.palettes} pal`);
  return st.join(' / ');
}

/*const toCard = d => ({
  id: d.id, title: d.partner, label: deliverySize(d), description: d.city && d.city.startsWith('Budapest') ? `${d.zip} ${d.street}` : `${d.country} ${d.zip} ${d.city}`
})
*/


const toCard = d => {
  let desc = d.city && d.city.startsWith('Budapest') ? `${d.zip} ${d.street}` : `${d.country} ${d.zip} ${d.city}`;
  if (d.contact) {
    desc += `\n${d.contact.name} ${d.contact.tel}`;
  }
  let backicon = (backwards(d)) ? <KeyboardDoubleArrowLeftIcon style={{ fontSize: 14 }}/> : '';

  return {
    id: d.id, title: <div style={{ overflow: 'hidden' }}> {backicon}  {d.partner} </div>, label : deliverySize(d), description: desc,
    style: { borderLeft: `5px solid ${bgcolor[d.company]}` }
  }
}

const matchingCards = (slots, term) => {
  if (!term) return slots;
  term = term.toLowerCase();
  return slots.filter(d => JSON.stringify(d).toLocaleLowerCase().includes(term))
}


const DriverApp = () => {
  let { asset } = useParams();
  const { setSidebar } = useSidebar();
  const [page, setPage] = useState(0);
  const json = useJSON({ url: '/delivery/driver', id: asset });
  const [deliveries, setDeliveries] = useState([]);
  const [cards, setCards] = useState({ lanes: [] });
  const [search, setSearch] = useState('');
  const refresh = useRefresh();
  const { setDialog } = useDialog();
  const [counter,setCounter] = useState({elokeszitve:0, kocsin:0, elfekvo:0, kesz:0});

  useEffect(register, []);

  useEffect(() => {
    if (json) {
      let { elokeszitve, kocsin, elfekvo, kesz } = json;
      let darray = [elokeszitve, kocsin, kesz, elfekvo];
      if (search){        
        darray = darray.map(d=>matchingCards(d, search));
      }
      let d = darray[page];
      setDeliveries(d);
      console.log("filtered data", d);
      let lane = {
        id: page, 
        cards: d.map(toCard)
      }
      setCards({ lanes: [lane] });
      setCounter({
        elokeszitve:darray[0].length, 
        kocsin:darray[1].length,
        kesz:darray[2].length,
        elfekvo:darray[3].length, 
      })
    }
  }, [json, search, page, setCards,setCounter,setDeliveries])

  const eventHandlers = {
    onCardClick: (cardId, metadata, laneId) => {
      setSidebar({ driver_delivery: cardId });
    },
    onCardMoveAcrossLanes: async (fromLaneId, toLaneId, cardId, index) => {
      console.log("onCardMoveAcrossLanes", { fromLaneId, toLaneId, cardId, index })
      let pos = deliveries[index].idx;
      const date = nowdate();
      await postCommand('delivery', COMMANDS.SCHEDULE_DELIVERY, { delivery: cardId, updates: { asset, date, idx: pos } });
      refresh();
    },    
  }

  return <Protected module="Sofőr felület"
  allowed={u => {
    console.log("Protected",u);
    if (!u) return false;
    if (u.munkakor === 'Vezetőség' || u.munkakor === 'Admin' || u.munkakor==='Sofőr') return true;
    if (u.reszleg && u.reszleg.includes('Raktár')) return true;
    return false;
  }}
  >
    <ConfirmProvider
      defaultOptions={{
        confirmationText: 'OK',
        cancellationText: 'Mégsem',
        confirmationButtonProps: { autoFocus: true }
      }}
    >
      <Box sx={{ display: 'flex' }}>
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }}>
          <Toolbar>
          <IconButton color="inherit" onClick={()=>refresh()}><Refresh/></IconButton>
            <Typography variant="h6" noWrap component="div" onClick={()=>refresh()}>
              {asset}
            </Typography>
            <SearchInput setSearch={setSearch}/>
          </Toolbar>
        </AppBar>
        <Board data={cards} laneDraggable={false} hideCardDeleteIcon cardDraggable={page===1} style={{
          background: '#fff',
          flexGrow: 1,
          paddingTop: 50,
          paddingLeft: 0,
          paddingRight: 0,
          marginBottom: 55
        }}
          cardStyle={{
            maxWidth: '94vw',
            minWidth: '94vw'
          }}
          laneStyle={{
            margin: 0,
          }}
          {...eventHandlers}
        />
        <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>        
          <Tabs value={page} onChange={(ev,value)=>setPage(value)} variant="fullWidth"    indicatorColor="secondary"
          textColor="inherit">
            <Tab icon={<Badge badgeContent={counter.elokeszitve ||null} color="secondary"><Inventory/></Badge>} color="inherit"></Tab>
            <Tab icon={<Badge badgeContent={counter.kocsin ||null} color="secondary"><LocalShipping/></Badge>}></Tab>
            <Tab icon={<Badge badgeContent={counter.kesz ||null} color="success"><Check/></Badge>}></Tab>
            <Tab icon={<Badge badgeContent={counter.elfekvo ||null} color="error"><Warning/></Badge>}></Tab>
          </Tabs>
        </AppBar>
        <Sidebar />
        <Dialog />
      </Box>
    </ConfirmProvider>
  </Protected >
}


export default DriverApp;